import React from "react";

function About({ content }) {
  const linkStyle = {
    color: "#010F7A",
    textDecoration: "none",
  };
  return (
    <div>
      <h1>Vishal Shenoy</h1>
      <p>
        I am a third year student at{" "}
        <a href="https://ucdavis.edu" target="_blank" style={linkStyle}>
          UC Davis
        </a>
        , currently taking a break from school to explore software development
        automation at{" "}
        <a href="https://codegen.com" target="_blank" style={linkStyle}>
          Codegen
        </a>
        .
      </p>
      <p>
        I enjoy programming and working on difficult technical problems with a
        focus on user experience.
      </p>
      <p>
        Last summer, I developed software for AI hardware systems at{" "}
        <a href="https://tenstorrent.com" target="_blank" style={linkStyle}>
          Tenstorrent
        </a>
        . Before that, I built internal tools for portfolio company management
        at{" "}
        <a href="https://conviction.com" target="_blank" style={linkStyle}>
          Conviction
        </a>
        .
      </p>
    </div>
  );
}

export default About;
